


import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "../../../config";
import CommonUserChoiceModal from "../../../common-components/CommonUserChoiceModal.vue";
import CommonDeleteModal from "../../../common-components/CommonDeleteModal.vue";
import { authHeader } from "../../../services/auth";
import { CONDITIONS_CATEGORY } from "../../../constants/constants";
import suneditor from "suneditor";
import plugins from "suneditor/src/plugins";

@Component({
  components: {
    CommonUserChoiceModal,
    CommonDeleteModal,
  },
})
export default class Conditions extends Vue {
  /* eslint-disable */
  public conditionsList = [];
  public conditionsData: any = {};
  public conditionsCategory = CONDITIONS_CATEGORY;
  public isEdit = false;
  public isHide = true;
  $modal: any;
  public editor: any = null;
  public isError = false;
  public inputId = 0;
  public search = "";
  public errorName = "";
  public errorCategory = "";
  public conditionName = "";
  public emailData: any = "";
  public category: any = "";
   public document: any = document;
   public emailList = [];
  public jsonData: any = [];
  public currentTemplate: any = {};
  public msg: any = null;
  $router: any;
  public allDataTemplate = [];
  public isDownload = 'No';
  // public async saveConditions() {
  //   try {
  //     this.conditionsData.name = this.editor.getContents();
  //     const response = await Axios.post(
  //       BASE_API_URL + "super-admin/conditions/saveConditions",
  //       this.conditionsData,
  //       {
  //         headers: authHeader(),
  //       }
  //     );
  //     if (response.data.msg === "success") {
  //       this.$snotify.success(
  //         this.isEdit ? "Updated Sucessfully" : "Saved Successfully"
  //       );
  //       this.conditionsData = {};
  //       this.$modal.hide("addConditions");
  //       this.inputId = 0;
  //       this.getConditionsList();
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }name.replace(/disabled=""/g, "");

  public editCondition(data: any) {
    this.$router.push({ path: "/edit-conditions", query: data });
    if(this.$route.query.isEdit){
    location.reload();
    }
  }

  public addCondition(data) {
    this.$router.push({ path: "/add-conditions", query: data });
  }

  // public async saveConditions() {
  //   try {
  //     this.conditionsData.name = this.editor.getContents();
  //     if (
  //       this.conditionsData.name
  //         .replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, "")
  //         .trim() != ""
  //     ) {
  //       this.errorName = "";
  //       this.isError = false;
  //     } else {
  //       this.isError = true;
  //       this.errorName = "Please add a Name";
  //       this.errorCategory = "Please add a Category";
  //     }
  //     if (
  //       this.conditionsData.name
  //         .replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, "")
  //         .trim() != "" &&
  //       this.conditionsData.category
  //     ) {
  //       const response = await Axios.post(
  //         BASE_API_URL + "super-admin/conditions/saveConditions",
  //         this.conditionsData,
  //         {
  //           headers: authHeader(),
  //         }
  //       );
  //       if (response.data.msg === "success") {
  //         this.$snotify.success(
  //           this.isEdit ? "Updated Sucessfully" : "Saved Successfully"
  //         );
  //         this.conditionsData = {};
  //         this.$modal.hide("addConditions");
  //         this.inputId = 0;
  //         this.getConditionsList();
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  public async getConditionsList() {
    this.callLoader(true, null);
    try {
      const response = await Axios.get(
        `${BASE_API_URL}super-admin/conditions/getConditionsList`,
        {
          params: {
            search: this.search,
            isDownload: 'No'
          },

          headers: authHeader(),
        }
      );
      this.conditionsList = response.data;
      this.jsonData = JSON.stringify(this.conditionsList);

    } catch (error) {
      console.log(error);
    }
    this.callLoader(false, null);
  }

public callLoader(loader, loaderTitleId) {
    this.$store.state.tmpcoLoader = loader;
    this.$store.state.loaderTitleId = loaderTitleId;
  }


 public async saveFile() {
    try {
       const response = await Axios.get(
        `${BASE_API_URL}super-admin/conditions/getConditionsList`,
        {
          params: {
            search: '',
            isDownload: 'Yes'
          },

          headers: authHeader(),
        }
      );
      if (response.status === 201) {
        this.conditionsList = response.data;
        this.jsonData = JSON.stringify(this.conditionsList);
        const fileUrl = window.URL.createObjectURL(
          new Blob([JSON.stringify(this.conditionsList)])
        );
        const fileLink = document.createElement("a");
        fileLink.href = fileUrl;
        fileLink.setAttribute("download", "conditions.seed.json");
        document.body.appendChild(fileLink);
        fileLink.click();
      }
    } catch (error) {
      console.log(error);
    }
  }
  public async conditionValue(data) {
    try {
      this.category = data.category;
      this.conditionName = data.name.replace(/disabled=""/g, "");
      this.$modal.show("testEmail");
    } catch (error) {
      console.log(error);
    }
  }

  public async sendTestMail() {
    try {
     // let  document: any = "";
      
      let topElement: any = this.document.getElementById("test-condition").children;
      for (let i = 0; i < topElement.length; i++) {
        const e = topElement[i];
        for (let j = 0; j < e.children.length; j++) {
          const ele = e.children[j];
          let inputField: any = ele;
          if (inputField.id)
            this.conditionName = this.conditionName.replace(/<input[^>]*>/, inputField.value);
        }
      }
      const data = {
        emailData: this.emailData,
        conditionName: this.conditionName,
        category: this.category,
      };
      

      const response = await Axios.post(
        BASE_API_URL + "test-email/sendCondtionTestMail",
        data,
        {
          headers: authHeader(),
        }
      );
      if (response.status === 201) {
        this.$modal.hide("testEmail");
        this.$snotify.success("Email sent Successfully");
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async deleteConditions(data) {
    try {
      await this.$refs.ref["delConfirmationBox"]({
        msg: `Are you sure want to delete this ${
          data.name
            .replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, "")
            .substr(0, 10) + "..."
        } Conditions ?`,
        data: { id: data._id },
        apiPath: "super-admin/conditions/deleteConditions",
      });
    } catch (error) {
      console.log(error);
    }
  }

  // public insertInput() {
  //   this.inputId += 1;
  //   this.editor.insertHTML(
  //     `<input id='input-${this.inputId}' name='value' type='text' disabled />`,
  //     true,
  //     true
  //   );
  // }
  /*********************/

  /****************************/

  // public async openConditionModal(list: "") {
  //   this.conditionsData = list ? list : {};
  //   this.$modal.show("addConditions");

  //   setTimeout(async () => {
  //     this.editor = await suneditor.create("pageEditor", {
  //       plugins: {
  //         ...plugins,
  //       },
  //       width: "auto",
  //       height: "100px",
  //       buttonList: [["bold"], ["list"]],
  //     });
  //     this.editor.insertHTML(this.conditionsData.name, true, true);
  //   }, 1);
  // }
  /**
   *  Hiding modal on cancel button
   */

  cancel() {
    this.$modal.hide("addConditions");
    this.isError = false;
    this.inputId = 0;
  }

  async mounted() {
    await this.getConditionsList();
  }
}
